"use client";

import { Button } from "../ui/button";
import {
    User,
    createClientComponentClient,
} from "@supabase/auth-helpers-nextjs";
import { useToast } from "../ui/use-toast";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { createSubscriberForNewsletter } from "@/supabase/subscribers";
import { createNewsletter } from "@/supabase/newsletters";
import { createAtRouteName } from "@/lib/utils";
import { CheckCircle, NotePencil } from "@phosphor-icons/react/dist/ssr";

interface CreateNewsletterCTAButtonProps {
    hasIcon: boolean;
    text: string;
    size?: "sm" | "icon" | "lg";
    user: User;
    variant?: "outline" | "default" | "ghost";
    className?: string;
}

const CreateNewsletterCTAButton: React.FC<CreateNewsletterCTAButtonProps> = ({
    hasIcon,
    text,
    size,
    variant,
    user,
    className,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const supabase = createClientComponentClient();
    const { toast } = useToast();
    const router = useRouter();
    const icon = <NotePencil size={18} />;

    const onClick = async () => {
        setIsLoading(true);
        const name = "My newsletter";

        const newlyCreatedNewsletter = await createNewsletter(supabase, {
            name,
            subject: "",
            type: "personal",
            creator_id: user.id!,
            cadence: "weekly",
            body_prompt: "",
            config: undefined,
            next_edition: 1,
            route: createAtRouteName(),
            next_release: null,
            is_v2: true,
        });

        // add creator as a subscriber
        await createSubscriberForNewsletter(
            supabase,
            newlyCreatedNewsletter.id!,
            user.email!
        );

        if (newlyCreatedNewsletter) {
            toast({
                title: "Creating your newsletter",
                description: "We are all set to design your email newsletter",
                duration: 5000,
                action: <CheckCircle color="#38b000" size={32} weight="fill" />,
            });

            router.push(`/home/newsletter/${newlyCreatedNewsletter.id}`);
        }
        setIsLoading(false);
    };

    return (
        <Button
            size={size}
            variant={variant}
            onClick={onClick}
            disabled={isLoading}
            className={className}
        >
            {hasIcon && <div className={hasIcon ? "mr-2" : ""}>{icon}</div>}
            {size != "icon" && text}
        </Button>
    );
};

export default CreateNewsletterCTAButton;
