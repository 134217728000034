"use client";

import { useMediaQuery } from "@/hooks/use-media-query";
import { cn } from "@/lib/utils";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import {
    Dialog,
    DialogTrigger,
    DialogHeader,
    DialogContent,
    DialogTitle,
    DialogDescription,
} from "../ui/dialog";
import {
    Drawer,
    DrawerTrigger,
    DrawerContent,
    DrawerHeader,
    DrawerTitle,
    DrawerDescription,
    DrawerFooter,
    DrawerClose,
} from "../ui/drawer";
import React from "react";
import { useToast } from "../ui/use-toast";

import parse, { Element } from "html-react-parser";
import { Sparkles } from "lucide-react";

function ProfileForm({
    className,
    closeModal,
}: React.ComponentProps<"form"> & { closeModal: () => void }) {
    const { toast } = useToast();
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [error, setError] = React.useState("");

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (name && email) {
            await fetch("/api/contact-us", {
                method: "POST",
                body: JSON.stringify({ name, email }),
            });
            toast({
                title: "We can't wait to meet you!",
                description:
                    "Thanks for getting in touch. We will reach out within 24 hours.",
                duration: 5000,
                action: <span style={{ fontSize: 32 }}>🎉</span>,
            });
            closeModal();
        } else {
            setError("Please fill in all fields.");
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={cn("grid items-start gap-4", className)}
        >
            <div className="grid gap-2">
                <Label htmlFor="name">Name</Label>
                <Input
                    id="name"
                    placeholder="Bloo Mai"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ fontSize: 16 }}
                    className="h-10"
                />
            </div>
            <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                    type="email"
                    id="email"
                    placeholder={"youremail@gmail.com"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ fontSize: 16 }}
                    className="h-10"
                />
            </div>
            {error && <span className="text-xs text-destructive">{error}</span>}

            <Button type="submit">
                <Sparkles className="mr-2" size={16} fill="white" />
                Get Bloom for Business
            </Button>
        </form>
    );
}

const Reachout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const isDesktop = useMediaQuery("(min-width: 768px)");

    const closeModal = () => setOpen(false);
    const subText =
        "Contact us to get business access to Bloom's AI-powered email marketing newsletter platform.";

    if (isDesktop) {
        return (
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>{children}</DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Get in touch</DialogTitle>
                        <DialogDescription>{subText}</DialogDescription>
                    </DialogHeader>
                    <ProfileForm closeModal={closeModal} />
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Drawer open={open} onOpenChange={setOpen}>
            <DrawerTrigger asChild>{children}</DrawerTrigger>
            <DrawerContent>
                <DrawerHeader className="text-left">
                    <DrawerTitle>Get in touch</DrawerTitle>
                    <DrawerDescription>{subText}</DrawerDescription>
                </DrawerHeader>
                <ProfileForm closeModal={closeModal} className="px-4" />
                <DrawerFooter className="pt-2">
                    <DrawerClose asChild>
                        <Button variant="outline">Cancel</Button>
                    </DrawerClose>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default Reachout;
