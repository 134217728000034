import { Tables } from "@/types/supabase";
import { SupabaseClient, User } from "@supabase/auth-helpers-nextjs";
import _ from "lodash";

export const createNewsletter = async (
    supabase: SupabaseClient,
    newsletter: INewsletter
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .insert([newsletter])
        .returns<Tables<"newsletters">>()
        .select()
        .single();

    if (error) {
        console.log(error);
    }

    return data as INewsletter;
};

export const getAllNewslettersByCreatorId = async (
    supabase: SupabaseClient,
    creatorId: string
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .select("*")
        .eq("creator_id", creatorId)
        .order("created_at", { ascending: false });
    // .returns<Tables<"newsletters">[]>();

    if (error) {
        console.log(error);
    }

    return data;
};

export const getPublishedNewsletters = async (supabase: SupabaseClient) => {
    const { data, error } = await supabase
        .from("newsletters")
        .select("*")
        .eq("is_published", true);

    if (error) {
        console.log(error);
    }

    return (data ? data : []) as INewsletter[];
};

export const getNewsletterWithBlocksById = async (
    supabase: SupabaseClient,
    id: string
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .select("*, blocks(*, elements(*))")
        .eq("id", id)
        .single();

    if (error) {
        console.log(error);
    }

    return data as INewsletter & { blocks: IBlock[] };
};

export const checkRouteAvailability = async (
    supabase: SupabaseClient,
    route: string
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .select("id")
        .eq("route", route)
        .maybeSingle();

    if (error) {
        console.log(error);
    }

    return !data;
};

export const setNewsletterPopulated = async (
    supabase: SupabaseClient,
    id: string
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .update({ should_populate: false })
        .eq("id", id)
        .single();

    if (error) {
        console.log(error);
    }
};

export const getNewsletterById = async (
    supabase: SupabaseClient,
    id: string
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .select("*, creator:creator_id(*)")
        .eq("id", id)
        .single();

    if (error) {
        console.log(error);
    }

    return data as INewsletter;
};

export const getNewsletterByRoute = async (
    supabase: SupabaseClient,
    route: string
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .select("*, emails(*)")
        .eq("route", route)
        .single();

    if (error) {
        console.log(error);
    }

    return data;
};

export const updateNewsletter = async (
    supabase: SupabaseClient,
    newsletter: INewsletter
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .update(newsletter)
        .eq("id", newsletter.id)
        .single();

    if (error) {
        console.log(error);
    }

    return data;
};

export const publishNewsletter = async (
    supabase: SupabaseClient,
    newsletter: INewsletter
) => {
    const { data, error } = await supabase
        .from("newsletters")
        .update(_.omit(newsletter, "creator"))
        .eq("id", newsletter.id)
        .single();

    if (error) {
        console.log(error);
    }

    return data;
};
