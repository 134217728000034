"use client";

import Link from "next/link";
import React from "react";
import { Button } from "../ui/button";
import { LogIn, Sparkles } from "lucide-react";
import { User } from "@supabase/auth-helpers-nextjs";
import Reachout from "./Reachout";
import { useMediaQuery } from "@/hooks/use-media-query";
import { useRouter } from "next/navigation";
import CreateNewsletterCTAButton from "../Business/CreateNewsletterCTAButton";
import { NotePencil } from "@phosphor-icons/react/dist/ssr";

interface LandingPageCTAProps {
    user: User | null;
    large?: boolean;
    isNav?: boolean;
}

const LandingPageCTA: React.FC<LandingPageCTAProps> = ({
    user,
    large,
    isNav,
}) => {
    const isDesktop = useMediaQuery("(min-width: 640px)");
    const router = useRouter();
    // console.log("isMobile", isMobile, "isNav", isNav);

    return (
        <div className="flex flex-row gap-2 items-center">
            <Reachout>
                {!(!isDesktop && isNav) && (
                    <Button
                        style={{
                            fontSize: large ? 15 : undefined,
                        }}
                        className="gap-1"
                        size={large ? "lg" : "sm"}
                    >
                        <Sparkles size={18} fill="white" />
                        Get Bloom for Business
                    </Button>
                )}
            </Reachout>
            {user ? (
                <CreateNewsletterCTAButton
                    user={user}
                    text="Create"
                    hasIcon
                    variant="outline"
                    size="sm"
                />
            ) : (
                <Button
                    size={large ? "lg" : "sm"}
                    onClick={() => {
                        router.push("/login");
                    }}
                    variant="outline"
                >
                    <LogIn size={18} className="mr-2" /> Login
                    <span className={"hidden sm:block ml-1"}>/ Signup</span>
                </Button>
            )}
        </div>
    );
};

export default LandingPageCTA;
