import { SupabaseClient } from "@supabase/auth-helpers-nextjs";

export const createSubscriberForNewsletter = async (
    supabase: SupabaseClient,
    newsletter_id: string,
    email: string
) => {
    const { data, error } = await supabase.from("subscribers").upsert(
        [
            {
                newsletter_id,
                email,
                name: "",
                on_cycle: 0,
                is_subscribed: true,
            },
        ],
        {
            onConflict: "newsletter_id,email",
        }
    );
    if (error) {
        throw error;
    }
    return data;
};

export const resubscribeSubscriber = async (
    supabase: SupabaseClient,
    subscriber_id: string
) => {
    const { data, error } = await supabase
        .from("subscribers")
        .update({ is_subscribed: true })
        .eq("subscriber_id", subscriber_id)
        .select();

    if (error) {
        throw error;
    }

    // console.log(subscriber_id, data);

    return data;
};

export const unsubscribeSubscriber = async (
    supabaseClient: SupabaseClient,
    subscriber_id: string
) => {
    const { data, error } = await supabaseClient
        .from("subscribers")
        .update({ is_subscribed: false })
        .eq("subscriber_id", subscriber_id);

    if (error) {
        throw error;
    }

    // console.log(subscriber_id, data);

    return data;
};

export const getSubscribersByNewsletterId = async (
    supabase: SupabaseClient,
    newsletter_id: string
) => {
    const { data, error } = await supabase
        .from("subscribers")
        .select("*")
        .eq("newsletter_id", newsletter_id)
        .eq("is_subscribed", true)
        .order("created_at", { ascending: true });

    if (error) {
        throw error;
    }

    return data;
};

export const getNumberOfSubscribersByNewsletterId = async (
    supabase: SupabaseClient,
    newsletter_id: string
) => {
    const { data, error, count } = await supabase
        .from("subscribers")
        .select("subscriber_id", { count: "exact" })
        .eq("newsletter_id", newsletter_id)
        .eq("is_subscribed", true);

    if (error) {
        throw error;
    }

    return count ?? 0;
};

export const isCurrentUserSubscribed = async (
    supabase: SupabaseClient,
    newsletter_id: string,
    email_id: string
) => {
    const { data, error } = await supabase
        .from("subscribers")
        .select("*")
        .eq("newsletter_id", newsletter_id)
        .eq("email", email_id)
        .eq("is_subscribed", true)
        .single();

    if (error) {
        console.log(error);
    }

    return !!data;
};
